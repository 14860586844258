<script lang="ts">
  import { createTrustedHTML } from "$lib/utils/security"

  export let icon: string
  export let className: string = ""

  // Validate and create trusted HTML
  const trustedIcon = createTrustedHTML(icon)
</script>

<svg class={className} viewBox="0 0 20 20" fill="none" aria-hidden="true">
  {@html trustedIcon}
</svg>
