<script lang="ts">
  const { modelId, sessionToken } = $props<{
    modelId: string
    sessionToken: string | undefined
  }>()

  let modelUrl = $state<string | null>(null)
  let isLoading = $state(true)
  let loadError = $state<string | null>(null)
  let iframeElement = $state<HTMLIFrameElement | null>(null)
  let iframeVisible = $state(false)

  async function loadModel() {
    if (!sessionToken) {
      loadError = "Please log in to view this 3D model"
      isLoading = false
      return
    }

    try {
      isLoading = true
      loadError = null

      console.log("Starting model load:", { modelId, hasToken: !!sessionToken })

      if (modelId.startsWith("http")) {
        modelUrl = modelId
        console.log("Using direct URL:", modelUrl)
      } else {
        const url = new URL(`https://o3d.io/e/${modelId}/share,link-hidden`)
        modelUrl = url.toString()
        console.log("Model URL set:", modelUrl)
      }
    } catch (error) {
      console.error("Model loading failed:", error)
      loadError =
        error instanceof Error ? error.message : "Failed to load 3D model"
    }
  }

  $effect(() => {
    loadModel()
  })
</script>

<div class="relative w-full h-[600px] bg-amber-50">
  {#if isLoading}
    <div class="loading-message">
      <p>Loading 3D model...</p>
    </div>
  {/if}

  {#if modelUrl}
    <iframe
      bind:this={iframeElement}
      src={modelUrl}
      title="3D Model Viewer"
      class="w-full h-full border-0"
      class:opacity-0={!iframeVisible}
      class:opacity-100={iframeVisible}
      style="transition: opacity 0.3s ease-in-out;"
      allow="autoplay; fullscreen; xr-spatial-tracking"
      loading="lazy"
      onload={() => {
        isLoading = false
        iframeVisible = true
      }}
      onerror={() => {
        isLoading = false
        loadError = "Failed to load 3D model"
      }}
    ></iframe>
  {/if}

  {#if loadError}
    <div class="error-message">
      <p>{loadError}</p>
    </div>
  {/if}
</div>

<style>
  .error-message,
  .loading-message {
    @apply absolute inset-0 flex items-center justify-center text-center p-4;
  }

  .error-message {
    @apply text-red-600 bg-red-50;
  }

  .loading-message {
    @apply text-amber-600 bg-amber-50;
  }
</style>
